/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from "react";

import { connect } from "react-redux";
import scrollTo from 'gatsby-plugin-smoothscroll';

import firebase from "./../utilities/firebase.js";

import Header from "../components/header";
import Footer from "../components/footer";

import { CheckCircleIcon, ChevronDownIcon } from "@heroicons/react/solid";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { navigate } from "gatsby";

const streamLengthArray = [
  { hours: 1, minutes: 60 },
  { hours: 2, minutes: 120 },
  { hours: 3, minutes: 180 },
  { hours: 4, minutes: 240 },
  { hours: 5, minutes: 300 },
  { hours: 6, minutes: 360 },
  { hours: 7, minutes: 420 },
  { hours: 8, minutes: 480 },
];

const howManyWillViewArray = [
  { displayName: "10%", percentage: 0.1 },
  { displayName: "20%", percentage: 0.2 },
  { displayName: "30%", percentage: 0.3 },
  { displayName: "40%", percentage: 0.4 },
  { displayName: "50%", percentage: 0.5 },
  { displayName: "60%", percentage: 0.6 },
  { displayName: "70%", percentage: 0.7 },
  { displayName: "80%", percentage: 0.8 },
  { displayName: "90%", percentage: 0.9 },
  { displayName: "100%", percentage: 1.0 },
];

const includedFeatures = [
  "High definition video",
  "High definition audio",
  "Stream directly on your store",
  "Visitors can ask questions",
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tiers = [
  {
    title: "Tier 1",
    totalPrice: 2400,
    frequency: "month",
    currency: "USD",
    pricePerMinute: 0.0028,
    overagePricePerMinute: 0.003,
    minutesIncludedInThisPlan: 1600000,
    calculatedStreamedAndViewedMinutes: 456546,
    cta: "Choose Tier 1",
    bestValue: false,
    contactSupport: false,
  },
  {
    title: "Tier 2",
    totalPrice: 2400,
    frequency: "month",
    currency: "USD",
    pricePerMinute: 0.0028,
    overagePricePerMinute: 0.003,
    minutesIncludedInThisPlan: 1600000,
    calculatedStreamedAndViewedMinutes: 456546,
    cta: "Choose Tier 1",
    bestValue: true,
    contactSupport: false,
  },
];

const Plans = ({ state, dispatch, props }) => {
  const [isTalkingToStripe, setIsTalkingToStripe] = useState(false);
  const [stripeCard, setStripeCard] = useState("");
  const [stripeElementError, setStripeElementError] = useState(false);
  const [isStripeElementCompleted, setIsStripeElementCompleted] =
    useState(false);
  const [user2, setUser] = useState(state);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [streamLength, setStreamLength] = useState(2);
  const [howManyWillView, setHowManyWillView] = useState("");
  const [plans, setPlans] = useState(false);
  const [salesContactDetails, setSalesContactDetails] = useState(false);

  const updateParent = (nextStep, plan) => {
    props.updateStep(nextStep);
    props.updateChosenPlan(plan);
  };

  const revealSalesContactDetails = () => {
    setSalesContactDetails("sales@nuine.co");
  };

  const calculateSubscriptionPrice = (formValues) => {
    var calculateSubscriptionPrice = firebase
      .functions()
      .httpsCallable("calculateSubscriptionPrice");

    calculateSubscriptionPrice({
      brandId: state.brandId,
      formValues: formValues,
    })
      .then((result) => {
        setFormSubmitting(false);
        setPlans(result.data);
        console.log("RESULT: ", result);
        setTimeout(() => {
          scrollTo('#planSelector')
        }, 100)
      })
      .catch((error) => {
        console.log(error);
      });
    return;
  };

  const numberWithSpaces = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  const changePlan = async (newTier) => {
    props.flipIsInitiatingSubscription(true)
    props.updateChosenPlan(newTier)
    console.log("New tier: ", newTier)
    setIsTalkingToStripe(true);
    var changeSubscriptionPlan = firebase
      .functions()
      .httpsCallable("changeSubscriptionPlan");

    changeSubscriptionPlan({
      newTier: newTier,
      brandId: state.brandId,
      subscriptionId: state.subscriptionId,
    }).then((response) => {
      console.log("Changed plan: ", response);
      setIsTalkingToStripe(false);
    });
  }

  const cancelSubscription = () => {
    setIsTalkingToStripe(true);
    props.flipIsInitiatingSubscription(true)
    props.updateChosenPlan('canceled')
    var cancelSubscription = firebase
      .functions()
      .httpsCallable("cancelSubscription");

    cancelSubscription({
      brandId: state.brandId,
      subscriptionId: state.subscriptionId,
    }).then((response) => {
      console.log("Cancel subscription: ", response);
      scrollTo('#top')
      /*dispatch(
        updateUserDetails({
          subscriptionStatus: "canceled",
        })
      );*/
      setIsTalkingToStripe(false);
      return response;
    });
  };

  return (
    <>
      <Header />
      <div className="grid grid-cols-6 gap-5 mx-5 xl:mx-auto xl:w-[1078px]">
        <section className="col-span-full pt-12 pb-24">
          {/* xs to lg */}
          <div className="pb-10">
            <h2 className="mb-12">Calculate your price and choose plan</h2>
            <div className="pb-16 border-b border-gray-200">
              <h3 className="mb-6">All plans comes with</h3>
              <ul
                role="list"
                className="mt-4 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5"
              >
                {includedFeatures.map((feature) => (
                  <li key={feature} className="flex items-center lg:col-span-1">
                    <div className="flex-shrink-0">
                      <CheckCircleIcon
                        className="h-5 w-5 text-fingreen-500"
                        aria-hidden="true"
                      />
                    </div>
                    <p className="ml-3 text-lg text-gray-700">{feature}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pt-16">
              <Formik
                initialValues={{
                  //TODO: Chnage to whats in the state
                  streamsEachWeek: "",
                  streamLength: 120,
                  monthlySessions: "",
                  howManyWillView: 0.1,
                }}
                validationSchema={Yup.object({
                  streamsEachWeek: Yup.string().required("Required"),
                  streamLength: Yup.string().required("Required"),
                  monthlySessions: Yup.string().required("Required"),
                  howManyWillView: Yup.string().required("Required"),
                })}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  console.log("VALUES: ", values);
                  setFormSubmitting(true);
                  calculateSubscriptionPrice(values);
                  setSubmitting(false);
                  setFormSubmitting(false);
                  //resetForm();
                }}
              >
                {({ errors, touched, setFieldValue, isValidating }) => (
                  <Form>
                    <div className="text-base space-y-2">
                      <div className="grid grid-cols-6 gap-8">
                        <div className="col-span-3">
                          <label htmlFor="streamsEachWeek">
                            Streams each week
                          </label>
                          <p className="text-base">
                            How many times do you estimate to stream each
                            week?
                          </p>
                        </div>

                        <div className="col-start-5 col-span-2">
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="number"
                              name="streamsEachWeek"
                              autoComplete="given-name"
                              placeholder="6"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.streamsEachWeek &&
                                touched.streamsEachWeek
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="streamsEachWeek">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-3">
                          <label htmlFor="streamLength">
                            Stream length (in hours)
                          </label>
                          <p className="text-base">
                            What is the average stream length for each stream
                            (in hours)? If you are unsure, you can leave it at
                            2.
                          </p>
                        </div>
                        <div className="col-start-5 col-span-2">
                          <div className="relative rounded-md mt-1">
                            <Field
                              id="streamLength"
                              name="streamLength"
                              as="select"
                              className={`${errors.streamLength && touched.streamLength
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              /*onChange={(e) => {
                                  
                                }}*/
                              onChange={(e) => {
                                console.log("e.target.value: ", e.target.value);
                                setStreamLength(e.target.value);
                                setFieldValue("streamLength", e.target.value);
                              }}
                            >
                              {streamLengthArray.map((length, i) => {
                                return (
                                  <option value={length.minutes} key={i}>
                                    {length.hours}
                                  </option>
                                );
                              })}
                              {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                            </Field>
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <ErrorMessage name="streamLength">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-3">
                          <label htmlFor="monthlySessions">
                            Monthly sessions
                          </label>
                          <p className="text-base">
                            How many monthly sessions do you have on your website?
                          </p>
                        </div>
                        <div className="col-start-5 col-span-2">
                          <div className="relative rounded-md mt-1">
                            <Field
                              type="number"
                              name="monthlySessions"
                              autoComplete="given-name"
                              placeholder="1000000"
                              //className="focus:ring-black focus:border-black block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                              className={`${errors.monthlySessions &&
                                touched.monthlySessions
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                            />
                          </div>
                          <ErrorMessage name="monthlySessions">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>
                        <div className="col-span-3">
                          <label htmlFor="howManyWillView">
                            How many will view the stream
                          </label>
                          <p className="text-base">
                            How many % of your visitors (sessions) will view the stream? If
                            you place the <b>nuine</b> widget on a subpage or further
                            down on the page, less people will see it.
                          </p>
                        </div>
                        <div className="col-start-5 col-span-2">
                          <div className="relative rounded-md mt-1">
                            <Field
                              id="howManyWillView"
                              name="howManyWillView"
                              as="select"
                              className={`${errors.howManyWillView &&
                                touched.howManyWillView
                                ? "border border-red-800 focus:ring-red-800 focus:border-red-800"
                                : "border border-gray-300 focus:ring-black focus:border-black"
                                } appearance-none block w-full px-3 py-2 border rounded-md placeholder-gray-400 focus:outline-none focus:ring-black focus:border-black sm:text-base`}
                              /*onChange={(e) => {
                                  
                                }}*/
                              onChange={(e) => {
                                console.log("e.target.value: ", e.target.value);
                                setHowManyWillView(e.target.value);
                                setFieldValue(
                                  "howManyWillView",
                                  e.target.value
                                );
                              }}
                            >
                              {howManyWillViewArray.map(
                                (howManyWillView, i) => {
                                  return (
                                    <option
                                      value={howManyWillView.percentage}
                                      key={i}
                                    >
                                      {howManyWillView.displayName}
                                    </option>
                                  );
                                }
                              )}
                              {/*<option value="United States">
                                United States
                              </option>
                            <option value="Canada">Canada</option>*/}
                            </Field>
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <ChevronDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                          </div>
                          <ErrorMessage name="howManyWillView">
                            {(error) => (
                              <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                                {error}
                              </span>
                            )}
                          </ErrorMessage>
                        </div>

                        {/*<button type="submit">Subscribe</button> */}
                      </div>
                      <div className="pt-8 text-right">
                        <button type="submit" className="primary-button">
                          Calculate
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {plans && (
            <div className="border-t border-gray-200">
              <div className="pt-16">
                <h2>Plans for stores of all sizes</h2>
                <p className="mt-6">
                  From your estimates we have calculated that you will have a
                  totalt of{" "}
                  <span className="font-bold">
                    {numberWithSpaces(
                      plans[0].calculatedStreamedAndViewedMinutes
                    )}
                  </span>{" "}
                  streamed and viewed minutes each month. We therefore pressent
                  you with the following options:
                </p>

                {/* Tiers */}
                <div className="mt-12 space-y-12 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8">
                  {plans.map((tier, i) => (
                    <div
                      key={tier.title}
                      className={classNames(
                        tier.bestValue
                          ? "border-coral-500"
                          : "border-gray-200",
                        "relative p-8 bg-white border rounded-2xl flex flex-col"
                      )}
                    >
                      <div className="flex-1">
                        <p className="text-xl text-gray-900">{tier.title}</p>
                        {tier.bestValue ? (
                          <p className="absolute top-0 py-1.5 px-4 bg-coral-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                            Best fit
                          </p>
                        ) : null}
                        {tier.bestValueTierIsTenPercentFromThisTier ? (
                          <p className="absolute top-0 py-1.5 px-4 bg-coral-500 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                            More room to grow
                          </p>
                        ) : null}
                        {tier.contactSales ? (
                          <p className="mt-4 flex items-baseline text-gray-900">
                            <span className="text-3xl font-bold tracking-tight">
                              Howdy, you big streamer! Please contact sales for
                              pricing.
                            </span>
                          </p>
                        ) : (
                          <>
                            <p className="mt-4 flex items-baseline text-gray-900">
                              <span className="text-3xl font-bold tracking-tight">
                                ${tier.totalPrice}
                              </span>
                              <span className="ml-1 text-xl font-semibold">
                                {`/${tier.frequency}`}
                              </span>
                            </p>

                            <p className="mt-1 flex items-baseline text-gray-600 text-sm">
                              (<span className="text-base mr-1">Minimum:</span>
                              <span className="font-bold tracking-tight">
                                ${tier.fixedMonthlyFee}
                              </span>
                              <span className="ml-1 font-semibold">
                                {`/${tier.frequency}`}
                              </span>
                              )
                            </p>

                            {/* Feature list */}
                            <ul role="list" className="mt-6 space-y-6">
                              <li className="flex flex-col">
                                <p className="ml-0 text-gray-500 text-sm">
                                  Included minutes:
                                </p>
                                <p className="ml-0 text-gray-600 text-base font-bold">
                                  {numberWithSpaces(
                                    tier.minutesIncludedInThisPlan
                                  )}
                                </p>
                              </li>
                              <li className="flex flex-col">
                                <p className="ml-0 text-gray-500 text-sm">
                                  Price per minute:
                                </p>
                                <p className="ml-0 text-gray-600 text-base font-bold">{`$${tier.pricePerMinute}`}</p>
                              </li>
                              <li className="flex flex-col">
                                <p className="ml-0 text-gray-500 text-sm">
                                  Overage price per minute:
                                </p>
                                <p className="ml-0 text-gray-600 text-base font-bold">{`$${tier.overagePricePerMinute}`}</p>
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                      {salesContactDetails && i === plans.length - 1 && (
                        <p className="text-gray-900 text-base mt-12">
                          Please send an email to{" "}
                          <span className="font-bold">
                            {salesContactDetails}
                          </span>{" "}
                          and we'll get in touch shortly.
                        </p>
                      )}
                      <button
                        onClick={() =>
                          !tier.contactSales
                            ? navigate("/sign-up")
                            : revealSalesContactDetails()
                        }
                        disabled={state.subscriptionStatus === "active" && state.subscriptionPlanId === tier.id}
                        className={classNames(
                          state.subscriptionStatus === "active" && state.subscriptionPlanId === tier.id ? "bg-gray-200 text-white" : (
                            tier.bestValue
                              ? "bg-coral-500 text-white hover:bg-coral-600"
                              : "bg-gray-100 text-black hover:bg-gray-200"),
                          "mt-8 block w-full py-3 px-6 border border-transparent rounded-full text-center font-medium"
                        )}
                      >
                        {"Sign up today"}

                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {state.subscriptionStatus === "active" && (

            <div className="border-t">
              <div>
                <h2 className="pt-8">Cancel your subscription</h2>
                <p className="mt-5">Your plan will terminate at end of this billing period</p>
                <div className="pt-8 text-right">

                  <button
                    onClick={() => cancelSubscription()}
                    className="primary-button"
                    disabled={isTalkingToStripe}
                  >
                    {isTalkingToStripe ? (
                      <svg
                        className="animate-spin h-5 w-5 flex justify-center align-middle text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      "End subscription"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
        </section>
        <div id="planSelector"></div>
      </div>
    </>
  );
};

export default connect(
  (state, props) => ({
    state: state.app,
    props: props,
  }),
  null
)(Plans);
